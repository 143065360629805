<template>
  <v-app>
    <Header></Header>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Header: () => import('./components/Header'),
  },
  data: () => ({
    //
  }),
  mounted() {
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this.colorThemeListener);
    document.cookie = 'uuid=';
    fetch('/js/country.txt')
      .then((res) => res.text())
      .then((res) => {
        this.$store.dispatch('country', res.toString().trim());
        localStorage.country = res.toString().trim();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },
  methods: {
    colorThemeListener(event) {
      if (event.matches) {
        localStorage.theme = 'dark';
        this.$vuetify.theme.dark = true;
      } else {
        localStorage.theme = 'light';
        this.$vuetify.theme.dark = false;
      }
    },
  },
  created() {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.$vuetify.theme.dark = isDark;
    localStorage.theme = isDark ? 'dark' : 'light';
  },
  beforeDestroy() {
    window.removeEventListener('change', this.colorThemeListener);
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.cursor-pointer {
  cursor: pointer;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lat: '',
    lng: '',
    dark: false,
    language: localStorage.language ? localStorage.language : 'de',
    country: '',
  },
  mutations: {
    lat(state, data) {
      // eslint-disable-next-line
      state.lat = data;
    },
    lng(state, data) {
      // eslint-disable-next-line
      state.lng = data;
    },
    dark(state, data) {
      // eslint-disable-next-line
      state.dark = data;
    },
    language(state, data) {
      // eslint-disable-next-line
      state.language = data;
    },
    country(state, data) {
      // eslint-disable-next-line
      state.country = data;
    },
  },
  getters: {
    lat(state) {
      return state.lat;
    },
    lng(state) {
      return state.lng;
    },
    dark(state) {
      return state.dark;
    },
    language(state) {
      return state.language;
    },
    country(state) {
      return state.country;
    },
  },
  actions: {
    lat({ commit }, data) {
      commit('lat', data);
    },
    lng({ commit }, data) {
      commit('lng', data);
    },
    dark({ commit }, data) {
      commit('dark', data);
    },
    language({ commit }, data) {
      commit('language', data);
    },
    country({ commit }, data) {
      commit('country', data);
    },
  },
});

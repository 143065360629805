<template>
  <div class="home">
    <v-container>
      <v-row justify="center" class="text-center pt-2">
        <v-col cols="12" class="">
          <v-row justify="center" align="center" class="text-center no-gutters pt-2 px-4">
            <v-col cols="11">
              <v-text-field
                label="Search restaurants and cuisines"
                placeholder="Thai, Italian..."
                solo
                rounded
                hide-details
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-4 mr-2"
                    fab
                    dark
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>
                      mdi-map-marker
                    </v-icon>
                  </v-btn>
                </template>
                <span>Search near me</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="mb-4" cols="12" md="12" lg="8">
          <address-search-at v-if="country === 'AT'"></address-search-at>
          <address-search-uk v-if="country === 'UK'"></address-search-uk>
        </v-col>

        <v-col class="mb-4" cols="12">
          <h1 class="display-2 font-weight-bold mb-3">
            {{ $t('app_name') }}
          </h1>

          <p class="subheading font-weight-regular">
            {{ $t('company_name') }}
          </p>
        </v-col>

        <v-col
          class="mb-5"
          cols="12"
        >
          <h2 class="headline font-weight-bold mb-3">
            What's next?
          </h2>

          <v-row justify="center">
            <a
              v-for="(next, i) in whatsNext"
              :key="i"
              :href="next.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ next.text }}
            </a>
          </v-row>
        </v-col>

        <v-col
          class="mb-5"
          cols="12"
        >
          <h2 class="headline font-weight-bold mb-3">
            Important Links
          </h2>

          <v-row justify="center">
            <a
              v-for="(link, i) in importantLinks"
              :key="i"
              :href="link.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ link.text }}
            </a>
          </v-row>
        </v-col>

        <v-col
          class="mb-5"
          cols="12"
        >
          <h2 class="headline font-weight-bold mb-3">
            Ecosystem
          </h2>

          <v-row justify="center">
            <a
              v-for="(eco, i) in ecosystem"
              :key="i"
              :href="eco.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ eco.text }}
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    AddressSearchAt: () => import('../components/general/AddressSearchAt'),
    AddressSearchUk: () => import('../components/general/AddressSearchUk'),
  },
  data: () => ({
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader',
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify',
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify',
      },
    ],
    importantLinks: [
      {
        text: 'Documentation',
        href: 'https://vuetifyjs.com',
      },
      {
        text: 'Chat',
        href: 'https://community.vuetifyjs.com',
      },
      {
        text: 'Made with Vuetify',
        href: 'https://madewithvuejs.com/vuetify',
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/vuetifyjs',
      },
      {
        text: 'Articles',
        href: 'https://medium.com/vuetify',
      },
    ],
    whatsNext: [
      {
        text: 'Explore components',
        href: 'https://vuetifyjs.com/components/api-explorer',
      },
      {
        text: 'Select a layout',
        href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
      },
      {
        text: 'Frequently Asked Questions',
        href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      lat: 'lat',
      lng: 'lng',
      dark: 'dark',
      country: 'country',
    }),
  },
  created() {
    console.log(this.$t('app_name'));

    // const searchRestaurants = () => {
    // eslint-disable-next-line max-len
    // this.$http.get(`searchwebshops?lat=${position.coords.latitude}&lng=${position.coords.longitude}&maxDistance=5&distanceUnit=km`)
    //   .then((response) => {
    //     console.log(response.data);
    //   });
    // };

    // const showPosition = (position) => {
    //   console.log(position.coords);
    //   localStorage.GeolocationPosition = JSON.stringify({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   searchRestaurants(position);
    // };

    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  },
};
</script>

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMeta from 'vue-meta';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import './plugins/dayjs';
import i18n from './plugins/i18n';

const { api_endpoint } = require('./config');

Vue.config.productionTip = false;

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : `/${api_endpoint}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-localization': 'en',
  },
});

Vue.use(VueAxios, api);
Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

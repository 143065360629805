import Vue from 'vue';
import VueRouter from 'vue-router';
import EmptyLayout from '../components/EmptyLayout.vue';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const lang = () => {
  if (localStorage.language) {
    return localStorage.getItem('language');
  }
  if (localStorage.fallbackLanguage) {
    return localStorage.getItem('fallbackLanguage');
  }
  return 'de';
};

const routes = [
  {
    path: '/',
    redirect: `/${lang()}`,
  },
  {
    path: '/:lang',
    component: EmptyLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
